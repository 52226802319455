import React from "react";
import Cookies from 'js-cookie';
import queryString from 'query-string';


class BaseView extends React.Component {
    constructor(props) {
        super(props)
        let id_token = null
        if (window.location != null) {
            let hash_location = window.location.href.split("#")
            if (hash_location.length === 2) {
                // let location = this.props.location.hash.substring(2, this.props.location.hash.length);
                const qs = queryString.parse(hash_location[1])
                id_token = qs.id_token
                // console.log("set id_token " + id_token)
            }
        }
        if (id_token == null) {
            id_token = Cookies.get('id_token')
        } else {
            Cookies.set('id_token', id_token)
        }

        this.state = {
          id_token: id_token,
          signin_url: "https://proxierauth.auth.us-east-1.amazoncognito.com/login?response_type=token&client_id=gcsvvjpgavrtos49tni7pd0dt&redirect_uri=https://proxier.io/login",
          signup_url: "https://proxierauth.auth.us-east-1.amazoncognito.com/signup?response_type=token&client_id=gcsvvjpgavrtos49tni7pd0dt&redirect_uri=https://proxier.io/login"
        };
    }
}

export default BaseView;
