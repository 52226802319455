import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import HomePage from './HomePage';
import RobinhoodLink from './RobinhoodLink';
import AdminPage from './AdminPage';
import { Redirect } from "react-router-dom";
import BaseView from "./BaseView";
// import { useLocation } from 'react-router-dom'


class Login extends BaseView {
    render() {
        if (this.state.id_token == null) {
            window.location.replace(this.state.signup_url)
            // return "setando id_token"
            return null
        } else {
            // return "id_token já registrado"
            return <Redirect to="/admin" />
        }
    }
}


class Main extends BaseView {
    render() {
        // let location = useLocation()
        // console.log('id_token ' + this.state.id_token)
        // console.log(this.props.location)

        return (
            <Router>
              <div>
                <Switch>
                  <Route path="/admin" component={AdminPage} />
                  <Route path="/login" component={Login} />
                  <Route path="/linkaccount" component={RobinhoodLink} />
                  <Route path="/" component={HomePage} />
                </Switch>
              </div>
            </Router>
        );
    }
}
export default Main;