import React from 'react'
import * as $ from 'jquery';

class LinkYourDevice extends React.Component {
    submitCode() {
        var code = $("#linkcode").val();
        window.location.href = "/linkaccount?linkcode=" + code;
    }

    render() {
        return <div>
            <div id="linkyourdevice-text">
                <h1>
                    <p>
                        Go to your Alexa app, install Home Investor skill and say: <br />
                        <span class="quote">“Alexa, open Home Investor”</span>
                    </p>
                    <p>
                        Alexa will tell you the code, if you want her to repeat, say: <br />
                        <span class="quote">“Alexa, please repeat the code”</span>
                    </p>
                </h1>

                <h3>Enter the code</h3>
                <input id="linkcode" type="text" name="linkcode" />
                <button id="btn-submit-code" class="btn btn-primary" onClick={this.submitCode}>Submit</button>
             </div>

        </div>
    }
}

export default LinkYourDevice
