import React from 'react';
import ContactForm from './ContactForm';
import LinkYourDevice from './LinkYourDevice';
import HowItWorks from './HowItWorks';
import BaseView from "./BaseView";
import { withRouter } from 'react-router-dom'

class HomePage extends BaseView {
    render() {
      return (
      <div>
        <div id="top-menu" class="content-section">
            <div class="top-left">
                <img alt="logo" class="logo-img" src="img/logo.svg" />
                <span class="logo-text">Home Investor</span>
            </div>
            <div class="top-right">
                <a href="#howitworks-content" class="link">How it Works</a>
                {/* <a href="#commands" class="link">Commands</a> */}
                <a href="#contact-content" class="link">Contact</a>
                <a href="#linkyourdevice-content" class="btn btn-primary">Link Your Device</a>
            </div>
        </div>
        <div id="top-content" class="content-section">
            <div id="top-content-box-left">
                <div id="top-content-box-left-inner">
                    <h1 class="top-content-text-left">
                        <p>Your Personal</p>
                        <p>Investment Advisor</p>
                    </h1>
                </div>
            </div>
            <div id="top-content-box-right">
                <div id="top-content-box-right-inner">
                    <div class="arrow-up"></div>
                    <div class="rectangle"></div>

                    <h1 class="top-content-text-right">
                        <p>Join Now</p>
                        <p>Enjoy it for Free</p>
                    </h1>
                 </div>
            </div>
        </div>
        <div id="linkyourdevice-content" class="content-section">
            <h2>Link Your Device</h2>
            <div id="linkyourdevice-app"><LinkYourDevice /></div>
        </div>
        <div id="howitworks-content" class="content-section">
            <h2>How it Works</h2>
            <div id="howitworks-app"><HowItWorks /></div>
        </div>
        <div id="contact-content" class="content-section">
            <h2>Contact</h2>
            <div id="contact-app"><ContactForm /></div>
        </div>
        <div id="footer" class="content-section">
            Copyright &copy; 2020 FDW Informatica
        </div>
      </div>
      );
    }

}

export default withRouter(HomePage)
