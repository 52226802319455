import React from 'react';
import ReactDOM from 'react-dom';

class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            type: 'info',
            message: ''
        };

    }

    handleSubmit(event) {
        event.preventDefault();
        // Scroll to the top of the page to show the status message.
        document.getElementById('contact-content').scrollIntoView();
        this.setState({ type: 'info', message: 'Sending...' }, this.sendFormData);
    }

    sendFormData() {
        let formData = {
            message: ReactDOM.findDOMNode(this.refs.message).value,
            email: ReactDOM.findDOMNode(this.refs.email).value,
            name: ReactDOM.findDOMNode(this.refs.name).value,
            phone: ReactDOM.findDOMNode(this.refs.phone).value
        };

        let xmlhttp = new XMLHttpRequest();
        let _this = this;
        xmlhttp.onreadystatechange = function() {
          if (xmlhttp.readyState === 4) {
            let response = JSON.parse(xmlhttp.responseText);
            if (xmlhttp.status === 200 && response.sent) {
              _this.setState({ type: 'success', message: 'We have received your message and will get in touch shortly. Thanks!' });
            }
            else {
              _this.setState({ type: 'danger', message: 'Sorry, there has been an error. Please try again later.' });
            }
          }
        };

        let URL = "https://api.proxier.io/contact";
        xmlhttp.open('POST', URL);
        xmlhttp.setRequestHeader('Content-type', 'application/json');
        xmlhttp.send(JSON.stringify(formData));
    }

    render() {
        if (this.state.type && this.state.message) {
          let classString = 'alert alert-' + this.state.type;
          var status = <div id="status" className={classString} ref="status">
                         {this.state.message}
                       </div>;
        }

        return (
          <div>
            <div id="contact-form-box">
            {status}
            <form action="" onSubmit={this.handleSubmit}>
              <div className="form-group">
                  <label htmlFor="name">Name <span className="required">*</span></label>
                <input className="form-control" name="name" ref="name" required type="text" />
              </div>
              <div className="form-group">
                  <label htmlFor="email">E-mail address <span className="required">*</span></label>
                <input className="form-control" name="email" ref="email" required type="email" />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone number</label>
                <input className="form-control" name="phone" ref="phone" type="phone" />
              </div>
              <div className="form-group">
                  <label htmlFor="message">Message <span className="required">*</span></label>
                <textarea className="form-control" name="message" ref="message" required rows="4" />
              </div>
              <div className="form-group">
                <button className="btn btn-primary" type="submit">Send</button>
              </div>
            </form>
            </div>
          </div>
        );
    }

}

export default ContactForm;

