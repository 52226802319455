import React from 'react';
import BaseView from "./BaseView";
import { withRouter } from 'react-router-dom'
import querySearch from "stringquery";
import ReactDOM from 'react-dom';
// import * as $ from 'jquery';


class RobinhoodLink extends BaseView {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            type: 'info',
            message: '',
            challenge: false,
            //fullform: false,
        };

       //window.robinhood_challenge_response = null;
       var query = querySearch(this.props.location.search);
       this.linkcode = query["linkcode"]
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ type: 'info', message: 'Sending...' }, this.sendFormData);
    }

    sendFormData() {
        // $(".hidden").attr("style", "display: block !important");
        // $(".hidden").removeClass("hidden");

        let formData = {
            linkcode: ReactDOM.findDOMNode(this.refs.linkcode).value,
            firstname: ReactDOM.findDOMNode(this.refs.firstname).value,
            robinhood_login: ReactDOM.findDOMNode(this.refs.robinhood_login).value,
            robinhood_password: ReactDOM.findDOMNode(this.refs.robinhood_password).value,
            robinhood_key: ReactDOM.findDOMNode(this.refs.robinhood_key).value,
            robinhood_challenge_response: ReactDOM.findDOMNode(this.refs.robinhood_challenge_response).value
        };
        // this.setState({ fullform: false });
        // $(".hidden").attr("style", "");

        let xmlhttp = new XMLHttpRequest();
        let _this = this;
        xmlhttp.onreadystatechange = function() {
          if (xmlhttp.readyState === 4) {
            let response = JSON.parse(xmlhttp.responseText);
            if (xmlhttp.status === 200) {
                _this.setState({
                    type: response.type,
                    message: response.message,
                    challenge: response.challenge,
                    linked: response.linked,
                });
                /*
                if (response.challenge) {
                    window.robinhood_challenge_response = window.prompt(response.message,"");
                    if (window.robinhood_challenge_response) {
                        _this.sendFormData();
                    }
                }*/
            }
            else {
              _this.setState({ type: 'danger', message: 'Sorry, there has been an error. Please try again later.' });
            }
          }
        };

        //let URL = "https://api.homeinvestor.io/api/linkaccount/";
        let URL = "http://localhost:5000/api/linkaccount/";
        xmlhttp.open('POST', URL);
        xmlhttp.setRequestHeader('Content-type', 'application/json');
        xmlhttp.send(JSON.stringify(formData));
    }

    render() {
        if (this.state.type && this.state.message) {
          let classString = 'alert alert-' + this.state.type;
          var status = <div id="status" className={classString} ref="status">
                         {this.state.message}
                       </div>;
        }

        var display_form = "";
        var display_challenge = "";
        var display_submit = "btn btn-primary";
        //if (!this.state.fullform) {
            if (this.state.linked) {
                display_form = "hidden";
                display_challenge = "hidden";
                display_submit = "hidden"
            } else if (this.state.challenge) {
                display_form = "hidden";
            } else {
                display_challenge = "hidden";
            }
        //}

        return (
        <div>
        <div id="linkaccount-content">
            <h2>Link Your Robinhood Account</h2>
          <div>
            <div id="contact-form-box">
            {status}
            <form action="" onSubmit={this.handleSubmit}>
              <div id="fullform" className={display_form}>
                  <div className="form-group">
                     <label htmlFor="linkcode">Your Device Link Code<span className="required">*</span></label>
                    <input className="form-control" id="linkcode" name="linkcode" ref="linkcode" required type="text" value={this.linkcode}/>
                  </div>
                  <div className="form-group">
                      <label htmlFor="firstname">Your First Name <span className="required">*</span></label>
                    <input className="form-control" name="firstname" ref="firstname" required type="text" />
                  </div>
                  <div className="form-group">
                     <label htmlFor="robinhood_login">Robinhood Login <span className="required">*</span></label>
                    <input className="form-control" name="robinhood_login" ref="robinhood_login" required type="email" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="robinhood_password">Robinhood Password <span className="required">*</span></label>
                    <input className="form-control" name="robinhood_password" ref="robinhood_password" required type="password" />
                  </div>
                  <div className="form-group">
                    <p>
                        To get the <b>Two-Factor Authentication</b> key, open your Robinhood App and go to: <br />
                        Account -> Settings -> Two-Factor Authentification -> Other Authentification App -> Add Manually<br />
                        Copy the key and paste it bellow.
                    </p>
                    <label htmlFor="robinhood_key">Robinhood Two-Factor Authentication Key <span className="required">*</span></label>
                    <input className="form-control" name="robinhood_key" ref="robinhood_key" required type="text" />
                  </div>
              </div>
              <div id="challengeform" className={display_challenge}>
                  <div className="form-group">
                    <label htmlFor="robinhood_challenge_response">Robinhood Challenge Code <span className="required">*</span></label>
                    <input className="form-control" name="robinhood_challenge_response" ref="robinhood_challenge_response" required={this.state.challenge? true : false} type="text" />
                  </div>
              </div>
              <div className="form-group">
                <button className={display_submit} type="submit">Link Account</button>
              </div>
            </form>
            </div>
          </div>

        </div>
        </div>
        );
    }

}

export default withRouter(RobinhoodLink)
