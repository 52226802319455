import React from 'react'

class HowItWorks extends React.Component {
  render() {
    return <div>
        <div id="howitworks-content-box">
            <div class="column-box">
                <div id="user-say-1">
                    <span class="user-ico"></span>
                    <div class="user-say-text">"Alexa, open Home Investor"</div>
                </div>
                <div id="user-say-2">
                    <span class="user-ico"></span>
                    <div class="user-say-text">"Yes, do you have any advice?"</div>
                </div>
                <div id="user-say-3">
                    <span class="user-ico"></span>
                    <div class="user-say-text">"Yes, please buy 1 share"</div>
                </div>
            </div>
            <div class="column-box">
                <img class="alexa-speaking" alt="Alexa" src="img/alexa-speaking.svg" />
            </div>
            <div class="column-box">
                <div id="alexa-say-1">
                    <div class="say-text">
                        “Welcome, your investments went up 1.05% since yesterday and 3,12% since last 30 days.
                        Market is doing overall good.<br />
                        Would you like to make a new investment today?”
                    </div>
                    <span class="alexa-ico"></span>
                </div>
                <div id="alexa-say-2">
                    <div class="say-text">
                    “Yes, <b>*your favorite company*</b> has shares being selled for $150.33, a price lower than usual.
                    Would you like to buy it?”
                    </div>
                    <span class="alexa-ico"></span>
                </div>
                <div id="alexa-say-3">
                    <div class="say-text">
                    “Ok, creating a market order to buy <b>*your favorite company*</b> for $150.33. I will let you know once it gets executed.”
                    </div>
                    <span class="alexa-ico"></span>
                </div>
            </div>
        </div>
    </div>
  }
}

export default HowItWorks
