import React from 'react'
import { Admin, Resource, fetchUtils } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
// import { ApplicationList, ApplicationEdit, ApplicationCreate, ApplicationIcon } from './ApplicationAdminResource';
import BaseView from "./BaseView";
import Cookies from 'js-cookie';
import { Toolbar, SaveButton, List, Datagrid, Edit, Create, SimpleForm, TextField, EditButton, TextInput } from 'react-admin';
// import AppsIcon from '@material-ui/icons/Apps';
import $ from 'jquery';


class AdminPage extends BaseView {
    EditToolbar = ({ props }) =>
        <Toolbar {...props}>
            <SaveButton handleSubmitWithRedirect={this.applicationSave}
                    // label="user.action.save_and_add"
                    // redirect="show"
                    // submitOnEnter={true}
                    // variant="flat"
                    // {...props}
            />
        </Toolbar>;

    ApplicationList = (props) => (
        <List {...props}>
            <Datagrid>
                <TextField source="id" />
                <TextField source="name" />
                <EditButton basePath="/application" />
            </Datagrid>
        </List>
    );

    ApplicationTitle = ({ record }) => {
        return <span>Application {record ? `"${record.name}"` : ''}</span>;
    };

    ApplicationEdit = (props) => (
        <Edit title={<this.ApplicationTitle />} {...props}>
            <SimpleForm toolbar={<this.EditToolbar />}>
                <TextInput disabled source="id" />
                <TextInput source="name" />
            </SimpleForm>
        </Edit>
    );

    // applicationSave(props) {
    applicationSave = () => {
        var myform = $(".simple-form")
        var disabled = myform.find(':input:disabled').removeAttr('disabled');
        var serialized = myform.serializeArray();
        var data = {};
        for(var s in serialized){
            data[serialized[s]['name']] = serialized[s]['value']
        }
        disabled.attr('disabled','disabled');
        console.log(data)
        return true
    }

    ApplicationCreate = (props) => (
        <Create title="Create a Application" {...props}>
            <SimpleForm>
                <TextInput source="name" />
            </SimpleForm>
        </Create>
    );

    constructor(props) {
        super(props)
        const fetchJson = (url, options = {}) => {
            if (!options.headers) {
                options.headers = new Headers({ Accept: 'application/json' });
            }
            // add your own headers here
            options.headers.set('Authorization', Cookies.get('id_token'));
            // options.headers.set('Authorization', `Bearer  ${"token"}`);

            return fetchUtils.fetchJson(url, options);
        }
        this.dataProvider = simpleRestProvider('http://api.localhost:8000', fetchJson);
    }

    render() {
        return (
          <div>
            <Admin
              title="Proxier"
              // authProvider={AuthProvider}
              dataProvider={this.dataProvider}
              // dataProvider={simpleRestProvider('http://api.localhost:8000')}
              // dataProvider={simpleRestProvider('https://api.proxier.io')}
              // loginPage={Login}
        //        logoutButton={MyLogoutButton}
            >
                <Resource name="application" list={this.ApplicationList} edit={this.ApplicationEdit} create={this.ApplicationCreate} icon={this.ApplicationIcon} />
            </Admin>
          </div>
        );
    }

}

export default AdminPage;