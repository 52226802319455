import React from 'react';
import ReactDOM from 'react-dom';
import Main from './Main';
// import Amplify from "aws-amplify";
// import awsExports from "./aws-exports";


//Amplify.configure(awsExports);


ReactDOM.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>,
  document.getElementById('main-app')
);
